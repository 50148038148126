import React, { useState } from "react";
//import {StyleSheet} from React;
import { TextField, Button, Typography, Container, Paper, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL} from "../../config/constants";
import LoadingButton from '@mui/lab/LoadingButton';

import { useAuth } from "../../providers/AuthProvider"; // Import the useAuth hook
import AuthAxios from '../../config/AuthAxios';
import "./login.scss";
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //Height: "100vh",
    //marginTop:'20vh',
    
   
  },
  paper: {
    padding: '40px',
    marginTop:'20vh',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: 'url(./images/logo_bkg.jpg)',
    // backgroundSize: 'cover', // Adjust this as needed
    
  },
  form: {
    width: "100%",
    marginTop: '20px',
  },
  submitButton: {
    marginTop: '20px',
  },
}));

  //const Login = ({setIsLoggedIn}) => {
  const Login = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth(); // Use the login function from AuthContext
  const [loading, setLoading] = React.useState(false);
  const {userToken} = useAuth();
  const api = AuthAxios(userToken);

  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    let formData = JSON.stringify({
      "username":username,
      "password":password,
       "type":"admin"
    })

    // Make an HTTP POST request to the backend API to create the user
   await api.post(
          
     'login', 
      formData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(response => {
        console.log(response);
        console.log(response.data);
        login(response.data.type,response.data.token,response.data.user); // Call the login function with the role
       
        setLoading(false)
        navigate('/'); // Redirect to the dashboard or desired page
    }).catch(error => {
      setLoading(false)
      //alert(error.response.data.error);
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_CENTER
      });
      console.log(error);
      //Alert.alert(error.message);
    });

  };

  return (
    <div className="main"   >
    <Container  className={classes.container} component="main" maxWidth="xs" >
    <ToastContainer />
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h5">Login</Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

        <LoadingButton
          color="primary"
          type="submit"
          fullWidth
          //onClick={handleClick}
          loading={loading}
          loadingPosition="start"
          className={classes.submitButton}
          //startIcon={<SaveIcon />}
          variant="contained"
        >
          <span>Login</span>
        </LoadingButton>

          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            Login
          </Button> */}
        </form>
      </Paper>
    </Container>
    </div>
  );
};



export default Login;
