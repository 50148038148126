import Login from "./pages/auth/Login";

import ViewUsers from "./pages/superadmin/ViewUsers";
import CreateUser from "./pages/superadmin/CreateUser";

import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "./style/dark.scss";
import { useContext} from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { useAuth } from "./providers/AuthProvider";

function App() {
  const { isLoggedIn} = useAuth(); // Use the useAuth hook
  console.log(isLoggedIn);
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Router>
        <Routes>
        {isLoggedIn ? ( 
        <Route path="/" >           
            
              <Route index element={<ViewUsers />} />
              <Route path="create" element={<CreateUser/>} />
              {/* <Route path=":userId" element={<ViewUser />} /> */}
            
            <Route path="*" element={<ViewUsers />} />
          </Route>):
            (<Route path="*" element={<Login />} />)
          }
          {/* Catch-all route for unknown paths */}
          {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
        </Routes>
      </Router>
    </div>
  );
}
export default App;
