import { useState } from "react";
import React from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box,Typography, useTheme, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";

import { useAuth } from "../../providers/AuthProvider"; // Import the useAuth hook

const Item = ({ title, to, icon, selected, setSelected }) => {

  return (
    <MenuItem
      active={selected === title}
      style={{
        // color: colors.primary[400],
        color: "#e2e2e2",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography variant="h12">{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar =  () => {
  const theme = useTheme();
  const { logout } = useAuth(); // Use the login function from AuthContext

  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout logic here
    logout();
    navigate("/login");
  };

  return (
    <Box
      
      sx={{
        //width:"20vh",
        height: "100vh", // Set the sidebar height to 100vh
        display: "flex",
        flexDirection: "column", // Make it a flex column container
        "& .pro-sidebar-inner": {
          background: `${colors.primary[100]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#DDD37B !important",
        },
        "& .pro-menu-item.active": {
          color: "#FFF59D !important",
        },
        // overflow: "hidden",
        //   overflowY: "scroll",
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            //icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            icon = {<MenuOutlinedIcon />}
            style={{
              margin: "12px 0 0px 0",
              color: colors.grey[100],
            }}
            
          ></MenuItem>

          {!isCollapsed && (
            <Box mb="20px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={
                    "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  }
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>

              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={'#ededed'}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {"Super Admin"}
                </Typography>

                <Typography
                
                variant="h5" 
                color={'#dddddd'}
                fontSize={'14px'}
                sx={{ mt: "2px" }}
                >
                  {"superadmin@daylight.com"}
                </Typography>
              </Box>
            </Box>
          )}

          <Box 
            position="relative"
            paddingLeft={isCollapsed ? undefined : "10%"}
            >
            
            <Box
              position="relative"
              bottom="0"
              left="0"
              textAlign="center"
              width="100%"
              sx={{mt:2}}
            >

            <Item
              title="View Users"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Add Users"
              to="/create"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


              {!isCollapsed ? (
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button
                variant="outlined"
                color="warning"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                ></Button>
              )}
            </Box>
          </Box>

          
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
