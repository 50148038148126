import React, { useState,useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Button,
  Box,
  useTheme,
  Typography,
} from "@mui/material";
import "./style/create.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from "../../theme";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DownloadIcon from '@mui/icons-material/Download';
import Header from "../../components/Header"
import UploadPopup from '../../components/UploadPopup';

import { useAuth } from "../../providers/AuthProvider";
import AuthAxios from '../../config/AuthAxios';

import { API_BASE_URL, STUDENTS_API,MEDIUMS_API,STANDARDS_API, BASE_URL } from "../../config/constants";

const CreateStudentForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediums, setMediums] = useState([]);
  const [classes, setClasses] = useState([]);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {userToken} = useAuth();
  const api = AuthAxios(userToken);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    entity_name: "",
    mobile: "",
    max_logins:"",
    student_limit: "",
    staff_limit: "",
    start_session:"",
    end_session:"",
    start_subscription : "",
    end_subscription : "",
  });


const createUser = async (userData) => {
  try {
    console.log(userData);

    const formData = new FormData();

      for (const key in userData) {
        formData.append(key, userData[key]);
      }

      // Append the selectedFile to the FormData
      if (selectedFile) {
        formData.append("user_image", selectedFile);
      }

    // Make an HTTP POST request to the backend API to create the user
    const response = await api.post(
      'users', 
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // The created user data will be available in the response object
    console.log("User created:", response.data);
    //toast("User created Succesfully!")
    return response.data;
  } catch (error) {
    // Handle errors if the request fails
    console.error("Error creating user:", error);
    throw error;
  }
};

const handleSubmit = async (event) => {
  event.preventDefault();
  console.log("Submit")
  try {
    const createdUser = await createUser(userData);

    refresh();
    toast.success('User created successfully !', {
      position: toast.POSITION.TOP_RIGHT
    });
    console.log("User created successfully:", createdUser);
  } catch (error) {
    // Handle the error case, e.g., show an error message
    toast.error('Error creating user !', {
      position: toast.POSITION.TOP_CENTER
    });
    console.error("Error creating user:", error);
  }
};

const handleChange = (event) => {
  const { name, value } = event.target;
  setUserData((prevUserData) => ({
    ...prevUserData,
    [name]: value,
  }));
};


const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]);
};

  const refresh = () => {
    Object.keys(userData).forEach(key => userData[key]="");
    setSelectedFile(null);
  };

  return (
    <div className="new">
      <div className="newContainer">
        <Navbar link={"/superadmin"} />
        <ToastContainer />
       
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
            sx={{
              backgroundColor: "#1976d2",
              padding: "10px 0",
              borderRadius: "10px", // Adjust the border radius value as needed
            }}
          >
            <h1 style={{ color: "white" }}>Create User</h1>
          </Box>

        <div className="bottom">
        <Box 
          //minHeight="100vh" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          //bgcolor="#e2e2e2"
        >
          <form enctype="multipart/form-data" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="left">
                  <img
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt=""
                  />
                </div>
              </Grid>

              <Grid item xs={6} rowSpan={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  height="100%"
                >
                  <input 
                    type="file"
                    name="user_image"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="image-upload"
                  />

                  <label htmlFor="image-upload">
                    <Button
                      variant="outlined"
                      startIcon={<DriveFolderUploadOutlinedIcon />}
                      color="primary"
                      component="span"
                    >
                      Upload Image
                    </Button>
                  </label>
                  {selectedFile && (
                    <div>Selected file: {selectedFile.name}</div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <TextField fullWidth label="Name" variant="outlined" name="name"  onChange={handleChange} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Entity Name" variant="outlined" name="entity_name"  onChange={handleChange} />
              </Grid>

              <Grid item xs={6}>
                <TextField fullWidth label="Email" variant="outlined" name="email"  onChange={handleChange} />
              </Grid>

              <Grid item xs={6}>
                <TextField fullWidth label="Mobile" variant="outlined" name="mobile"  onChange={handleChange} />
              </Grid>


              <Grid item xs={4}>
                <TextField  fullWidth label="Student Limit" variant="outlined" name="student_limit" 
                inputProps={{
                  pattern: "\\d*", // Regular expression to match only numbers
                }}

                onChange={handleChange}/>
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Staff Limit" variant="outlined" name="staff_limit"  onChange={handleChange}/>
              </Grid>
              <Grid item xs={4}>
                <TextField fullWidth label="Max Logins" variant="outlined" name="max_logins"  onChange={handleChange}/>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Start Session Date"
                  type="date"
                  variant="outlined"
                  name="start_session"  
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true, // This will remove the placeholder text
                }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="End Session Date"
                  type="date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This will remove the placeholder text
                }}
                  name="end_session"  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Start Subscription Date"
                  type="date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This will remove the placeholder text
                }}
                  name="start_subscription"
                  onChange={handleChange}
                />
              </Grid>
              
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="End Subscription Date"
                  type="date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true, // This will remove the placeholder text
                }}
                  name="end_subscription"  
                  onChange={handleChange}
                />
              </Grid>
            

              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default CreateStudentForm;
