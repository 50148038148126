import "./style/list.scss";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  useTheme,
  Button,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast, ToastContainer } from "react-toastify";

import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useAuth } from "../../providers/AuthProvider";
import AuthAxios from "../../config/AuthAxios";

const SuperDashboard = () => {
  const dataRef = useRef(null);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);

  const { userToken } = useAuth();
  const api = AuthAxios(userToken);

  const handleDelete = (id) => {
    //setData(data.filter((item) => item.id !== id));
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "entity_name",
      headerName: "Entity Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      type: "number",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        var link = "/user/" + params.row.id;
        return (
          <div className="cellAction">
            <Button
              variant="outlined"
              sx={{ backgroundColor: "#e2e2e2", borderColor: "white" }}
              startIcon={<VisibilityIcon />}
              href={link}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    api
      .get("users")
      .then((user) => {
        console.log(user)
        let data = user.data;
        let userData = data.map((userData) => ({id: userData.admin_id, ...userData}))
        setData(userData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Function to call the API and update class_id values
  const activateUser = async (userIds) => {
    try {
      const response = await api.post("users/promote", { userIds });
      console.log(response.data.message); // Output success message from the server
      setSelectedValue([]);
      api
        .get("users")
        .then((users) => {
          setData(users.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      dataRef.current.refreshCells();
      toast.success("User activated successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      // Show error toast message
      toast.error("Error activating users!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchText(searchTerm);

    api
      .get("users", {
        params: {
          searchText: searchText,
          // start: start,
          // limit: limit,
        },
      })
      .then((response) => {
        const data = response.data; // Assuming the response contains the filtered data from the API
        console.log(data);
        setData(data); // Update the state with the filtered data from the API
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    api
      .get("users", {
        params: {
          searchText: searchText,
        },
      })
      .then((response) => {
        const data = response.data; // Assuming the response contains the filtered data from the API
        console.log(data);
        setData(data); // Update the state with the filtered data from the API
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="list">
            <Sidebar/>

      <div className="listContainer">
        <Navbar link="/" />
        <Box m="20px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
            sx={{
              backgroundColor: "#1976d2",
              padding: "10px 0",
              borderRadius: "10px", // Adjust the border radius value as needed
            }}
          >
            <h1 style={{ color: "white" }}>Users</h1>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={selectedValue.length <= 0}
              color="secondary"
              startIcon={<TrendingUpIcon />}
              sx={{ color: "red", mr: 2 }}
              onClick={() => activateUser(selectedValue)}
            >
              Activate
            </Button>

            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              color="primary"
              href="/superadmin/create"
              sx={{ mr: 2 }}
            >
              Add New User
            </Button>

            <Box
              sx={{ mr: 2 }}
              backgroundColor={colors.primary[400]}
              borderRadius="3px"
            >
              <InputBase
                sx={{ ml: 2, flex: 1 }}
                placeholder="Search"
                onChange={handleSearch}
              />
              <IconButton type="button" sx={{ p: 1 }}>
                <SearchIcon />
              </IconButton>
            </Box>
          </Box>

          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[900],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              ref={dataRef}
              className="datagrid"
              key={data.length}
              rows={data}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                let selectedArray = Array.from(selectedIDs);
                setSelectedValue(selectedArray);
              }}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default SuperDashboard;
