import "./navbar.scss";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Navbar = ({link}) => {

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Function to update the currentDateTime state every second
  const updateDateTime = () => {
    setCurrentDateTime(new Date());
  };

  useEffect(() => {
    // Set up an interval to update the time every second
    const intervalId = setInterval(updateDateTime, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Options for formatting the date and time
  const dateTimeOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };


  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          
        <IconButton 
          type="button" 
          sx={{ p: 1 }} 
          href={link}
          
        >
          <ArrowBackIcon />
        </IconButton>

        
       
          {/* <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon /> */}
        </div>
        <div className="item"  style={{marginTop:'10px'}}>
            <span className="date-time" style={{ fontWeight:'bold' , fontSize:'18px', marginTop:'10px' }}>
              {currentDateTime.toLocaleDateString("en-US", dateTimeOptions).replace(',','')}
            </span>
          </div>
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
          <div className="flag">
          <img
              height={"30px"}
              style={{marginTop:'10px'}}
              src="https://erp.enigmascript.com/assets/images/india_flag.gif"
              alt=""
              className="avatar"
            />
          </div>
         
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div> */}
          <div className="item"  style={{marginTop:'10px'}}>
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          {/* <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div> */}
          {/* <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            <img
             style={{marginTop:'10px'}}
              src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
